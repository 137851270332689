<template>
  <div>
    <b-row class="match-height">
      <b-col>
        <h2 class="font-medium-3">
          Bảng tin
        </h2>
      </b-col>

    </b-row>

  </div>

</template>

<script>
import { BRow, BCol } from 'bootstrap-vue';

// eslint-disable-next-line import/no-cycle
import SelectApartment from '@/views/components/SelectApartment.vue';
import MedalCard from './items/MedalCard.vue';
import TenantCard from './items/TenantCard.vue';
import FinanceCard from './items/FinanceCard.vue';
import PropertyCard from './items/PropertyCard.vue';
import ContractCard from './items/ContractCard.vue';
import TaskCard from './items/TaskCard.vue';

export default {
  components: {
    BRow,
    BCol,
    MedalCard,
    TenantCard,
    SelectApartment,
    FinanceCard,
    PropertyCard,
    ContractCard,
    TaskCard,
  },
  setup() {

  },
  data() {
    return {
      apartment: null,
    };
  },
};
</script>
