<template>
  <b-card
    no-body
  >
    <b-card-body class="pb-0">
      <div class="d-flex align-items-center">
        <h2 class="font-weight-bolder">
          {{ total }}
        </h2>
        <b-badge
          pill
          variant="light-danger"
          class="font-small-1 ml-50"
        >
          {{ newTasks }} việc mới
        </b-badge>
      </div>

      <h6 class="text-muted">
        Công việc/Sự cố
      </h6>

    </b-card-body>

    <b-row
      class="my-2 mx-0"
    >
      <b-col cols="4">
        <div
          class="rounded border p-50 text-center"
          style="{border-style: dotted !important;}"
        >
          <h2 class="font-weight-bolder font-medium-1">
            {{ inProgressTasks }}
          </h2>
          <h6 class="text-muted font-small-2">
            Đang làm
          </h6>
        </div>
      </b-col>
      <b-col cols="4">
        <div
          class="rounded border p-50 text-center"
          style="{border-style: dotted;}"
        >
          <h2 class="font-weight-bolder font-medium-1">
            {{ expiredTasks }}
          </h2>
          <h6 class="text-muted font-small-2">
            Quá hạn
          </h6>
        </div>
      </b-col>
      <b-col cols="4">
        <div
          class="rounded border p-50 text-center"
          style="{border-style: dotted;}"
        >
          <h2 class="font-weight-bolder font-medium-1">
            {{ completeTasks }}
          </h2>
          <h6 class="text-muted font-small-2">
            Đã xong
          </h6>
        </div>
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import {
  BCard, BBadge, BRow, BCol, BCardBody,
} from 'bootstrap-vue';
import useJwt from '@/auth/jwt/useJwt';

export default {
  components: {
    BCard,
    BBadge,
    BRow,
    BCol,
    BCardBody,
  },
  props: {
    apartment: {
      type: [Object, null],
      default: null,
    },
  },
  data: () => ({
    total: 0,
    newTasks: 0,
    inProgressTasks: 0,
    completeTasks: 0,
    expiredTasks: 0,
  }),
  watch: {
    apartment() {
      this.fetchData(this.apartment);
    },
  },
  created() {
    this.fetchData(null);
  },
  methods: {
    fetchData(apartment) {
      useJwt.getReportTaskGeneralData(apartment).then(response => {
        this.total = response.data.total;
        this.newTasks = response.data.newTasks;
        this.inProgressTasks = response.data.inProgressTasks;
        this.completeTasks = response.data.completeTasks;
        this.expiredTasks = response.data.expiredTasks;
      });
    },
  },
};
</script>
